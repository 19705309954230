import React from 'react';
import ServiceCard from '../components/ServiceCard';

function Services() {
  return (
    <section className="bg-white text-center mt-4 lg:mt-16">
      <h2 className="text-base font-semibold uppercase tracking-wider text-primaryblue">Nossa Expertise</h2>
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl px-4">
          Onde oferecemos nosso conhecimento
        </p>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500 mb-8 px-8">
          Compartilhamos com você toda a nossa experiência no mundo dos negócios e colocamos você no mesmo nível que empresas multinacionais
        </p>
      <div className="sm:px-32 md:px-4 2xl:px-96 lg:mt-16">
        <article className="grid grid-cols-1 md:grid-cols-3">
          <ServiceCard
              title="Site"
              text="Informações da sua empresa em um espaço exclusivo, responsivo e com a cara do seu negócio"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-bg1 md:w-52 max-h-60 opacity-10 absolute -left-28" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clipRule="evenodd" />
                </svg>
              }
          />
          <ServiceCard
              title="Landing Page"
              text="Dê destaque para um produto ou serviço específico e aumente a sua visibilidade"
              icon={<svg xmlns="http://www.w3.org/2000/svg" className="svg-bg2 md:w-52 max-h-60 opacity-10 absolute -left-28" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z" clipRule="evenodd" />
            </svg>}
          />
          <ServiceCard
              title="Aplicativo"
              text="Coloque sua empresa a apenas um toque dos seus clientes"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-bg3 md:w-52 max-h-60 opacity-10 absolute -left-24" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                </svg>
              }
          />
        </article>
        <article className="grid grid-cols-1 md:mx-36 md:grid-cols-2">
          <ServiceCard
            className="md:mt-1"
            title="Plataforma Digital"
            text="A gestão dos seus dados em um ambiente prático e seguro"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-bg4 md:w-52 max-h-48 opacity-10 absolute -left-20"  viewBox="0 0 20 20" fill="currentColor">
                <path d="M3 12v3c0 1.657 3.134 3 7 3s7-1.343 7-3v-3c0 1.657-3.134 3-7 3s-7-1.343-7-3z" />
                <path d="M3 7v3c0 1.657 3.134 3 7 3s7-1.343 7-3V7c0 1.657-3.134 3-7 3S3 8.657 3 7z" />
                <path d="M17 5c0 1.657-3.134 3-7 3S3 6.657 3 5s3.134-3 7-3 7 1.343 7 3z" />
              </svg>
            }
          />
          <ServiceCard
            className="md:mt-1"
            title="Estratégia para redes sociais"
            text="Aumente o engajamento e crie mais oportunidades para seu negócio"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-bg5 md:w-52 max-h-48 opacity-10 absolute -left-20" viewBox="0 0 20 20" fill="currentColor">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
              </svg>
            }
          />
        </article>
      </div>
    </section>  
  )
}

export default Services;