import React from 'react';
import pitchImage from '../assets/imgs/FOTO.png';
// import secondImg from '../assets/imgs/image2.jpeg';


function Story() {
  return(
    <section className="text-center bg-primaryblue items-center mt-20">
      <div className="relative">
      <div className="h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 max-w-screen-lg">
        <img
          className="w-full h-full object-cover opacity-80"
          src={ pitchImage }
          alt=""
        />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">Excelência em negócios</h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">Podemos te ajudar!</p>
          <p className="mt-3 text-lg text-gray-300">
            Fale com o nosso time de atendimento para saber como podemos te ajudar da melhor forma para alavancar o seu negócio e crescer junto com a gente.
          </p>          
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                target="_blank" rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=5514981246611"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white opacity-90 hover:opacity-100 hover:scale-110 duration-150 ease-out"
              >
                Entrar em contato
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  )
}

export default Story;