import React from 'react';

// import ProductCard from '../components/ProductCard';
// import podtech from '../assets/imgs/logos/Logo PodTech redesenhada.png';

import {
  MicrophoneIcon,
  DeviceMobileIcon,
  ChatAlt2Icon,
  GlobeAltIcon,
  PresentationChartLineIcon,
  TemplateIcon
} from '@heroicons/react/outline'

const features = [
  {
    color: 'primarypurple',
    name: 'Estúdio Podtech',
    description: 'Estúdio altamente preparado para a criação das suas demandas audiovisuais',
    icon: MicrophoneIcon
  },
  {
    color: 'secondarypurple',
    name: 'Pedido na Mão',
    description: 'Acessibilidade integrada ao WhatsApp para o delivery do seu empreendimento',
    icon: DeviceMobileIcon
  },
  {
    color: 'tertiarypurple',
    name: 'Usain Bot',
    description: 'Maximize a eficiência de atendimentos com o nosso chatbot customizável',
    icon: ChatAlt2Icon
  },
  {
    color: 'tertiaryorange',
    name: 'Além do ERP',
    description: 'Revolucionária plataforma que unifica a comunicação entre sistemas de informação',
    icon: GlobeAltIcon
  },
  {
    color: 'secondaryorange',
    name: 'Gestor de Processos',
    description: 'Gerenciamento consciente e eficaz do fluxo de processos para todo tipo de negócio',
    icon: PresentationChartLineIcon
  },
  {
    color: 'primaryorange',
    name: 'Cadastro Dinâmico',
    description: 'Ferramenta de desenvolvimento ágil e dinâmico de plataformas para sua empresa',
    icon: TemplateIcon
  },
]

const css = {
  primarypurple: {backgroundColor: '#48057b'},
  secondarypurple: {backgroundColor: '#800fcd'},
  tertiarypurple: {backgroundColor: '#e893fa'},
  primaryorange: {backgroundColor: '#f04157'},
  secondaryorange: {backgroundColor: '#ff6d3b'},
  tertiaryorange: {backgroundColor: '#fdd06c'},
}

function Products() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-20">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold uppercase tracking-wider text-primaryblue">Produtos Exclusivos</h2>
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Tudo que você precisa para destacar seu negócio
        </p>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          A AGA Tecnologia acredita no potencial de todos e, por isso, desenvolvemos produtos diferenciados e essenciais para alcançar o maior potencial da sua empresa.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6 hover:scale-110 duration-200 ease-out">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8 hover:shadow-lg">
                  <div className="-mt-6">
                    <div>
                      <span style={css[feature.color]} className={`inline-flex items-center justify-center rounded-md bg-${feature.color} p-3 shadow-lg`}>
                        <feature.icon className="h-10 w-10 lg:h-6 lg:w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
              </div>)
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products;