import React from 'react';
import logoBg from '../assets/imgs/aga-tecnologia-mono.png';
import '../assets/css/logoBg.css';
import pitchImage from '../assets/imgs/milior.jpg';

function Pitch() {
  // const images = ['https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'];
  const style = { banner: { height: '400px' }, logoBg: { height: '720px', width: 'auto'} }

  return (
    <section className="grid grid-row-2">
      <aside>
        <div className="relative">
          <img /*style={ style.banner } */ className="z-10 w-full lg:h-96 uw:h-ultra-wide lg:object-cover lg:object-top opacity-100" src={ pitchImage } alt="AGA Tecnologia" />
          <span className="h-full w-full absolute bg-primaryblue top-0 opacity-50"></span>
        </div>
      </aside>
      <article className="flex flex-col h-full justify-evenly py-4 md:py-16 md:px-48 xl:px-96 relative overflow-hidden">
        <img style={ style.logoBg } className="logoBgMob absolute opacity-5 top-60 lg:top-0 xl:top-0 lg:-left-32 xl:-left-40" src={ logoBg } alt="" />
        <h2 className="text-primaryblue pb-12 font-bold text-4xl text-center">
          Nós somos a AGA Tecnologia!
        </h2>
        <p className="text-center text-lg px-6 xl:px-2 2xl:px-24 max-w-screen-md mx-auto">
          Muito embora nós somos uma empresa de <strong className="text-primaryblue">tecnologia</strong>, a gente não busca vender softwares ou soluções que prometem revolucionar sua empresa. O que a gente quer de verdade é <strong className="text-primaryblue">garantir</strong> que nossos produtos e serviços agreguem <strong className="text-primaryblue">valor</strong> dentro do seu negócio.
          <br />
          <br />
          Com isso, em Abril de 2020, nasce a <strong className="text-primaryblue">AGA Tecnologia</strong> - uma startup, que desde a sua fundação, já sabia que inovar não era suficiente e que sua <strong className="text-primaryblue">missão</strong> seria muito mais que utilizar apenas tecnologia na jornada do crescimento.
          <br />
          <br />
          Pensem com a gente - juntamos toda a sua <strong className="text-primaryblue">expertise</strong> de negócios com o nosso <strong className="text-primaryblue">know-how tech</strong> para proporcionar resultados de grande impacto positivo. Queremos ser o GPS dessa jornada. Então, vem com a gente, que esse é o início de uma <strong className="text-primaryblue">parceria</strong> única e exclusiva!
        </p>
      </article>   
    </section>
  )
}

export default Pitch;
