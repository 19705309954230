import React from 'react';
import logoLetters from '../assets/imgs/aga-tecnologia-white-only.png';
import {
  BsWhatsapp,
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsEnvelopeFill
} from "react-icons/bs";

function Footer() {
  const date = new Date();
  const year = date.getFullYear();

  const navigation = [
    {
      name: 'WhatsApp',
      href: 'https://api.whatsapp.com/send?phone=5514981246611',
      icon: <BsWhatsapp />
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/agatecnologia/',
      icon: <BsInstagram />
    },
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/agatecnologia.br/',
      icon: <BsFacebook />
    },
    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/company/aga-tecnologia/',
      icon: <BsLinkedin />
    },
    {
      name: 'Gmail',
      href: 'mailto: nelise.camargo@agatecnologia.com.br',
      icon: <BsEnvelopeFill />
    },
  ]

  return (
    <>
      <div className="gradient-animation"/>
      <footer className="bg-primaryblue">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-4">
          <div className="flex justify-center space-x-6 md:order-2">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-white opacity-80 hover:opacity-100 hover:scale-125 ease-out duration-150" target="_blank" rel="noreferrer">
                <span className="sr-only">{item.name}</span>
                {item.icon}
              </a>
            ))}
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-white">
              <img className="h-4 inline pr-2" src={ logoLetters } alt="AGA TECNOLOGIA" />
              &copy; { year }
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}


export default Footer;
