import React from 'react';
import '../assets/css/role-color.css';

import fer1 from '../assets/imgs/team/fernando-1.png';
import fer2 from '../assets/imgs/team/fernando-2.png';
import hel1 from '../assets/imgs/team/helisson-1.png';
import hel2 from '../assets/imgs/team/helisson-2.png';
import hic1 from '../assets/imgs/team/hicaro-1.png';
import hic2 from '../assets/imgs/team/hicaro-2.png';
import leo1 from '../assets/imgs/team/leonardo-1.jpg';
import leo2 from '../assets/imgs/team/leonardo-2.png';

function Team() {
  const people = [
    {
      name: 'Helisson Camargo',
      role: 'CEO',
      image: hel1,
      imageHover: hel2,
      bio: 'Idealizador e fundador da empresa, mente criativa, Desenvolvedor Full Stack sênior com mais de 12 anos de experiência em programação e mais de 15 em negócios',
    },
    {
      name: 'Leonardo Diman',
      role: 'CDO',
      image: leo1,
      imageHover: leo2,
      bio: 'Internacionalista de formação, comunicador por natureza e desenvolvedor Front End apaixonado por solucionar problemas e facilitar processos',
    },
    {
      name: 'Hícaro Bassa',
      role: 'COO',
      image: hic1,
      imageHover: hic2,
      bio: 'MBA em Gestão de Negócios, gestor com mais de 13 anos de experiência com vendas em diversas áreas, coordenador de equipes e apaixonado pela inovação',
    },
    {
      name: 'Fernando Stefanutto',
      role: 'CTO',
      image: fer1,
      imageHover: fer2,
      bio: 'Empreendedor e Desenvolvedor Full Stack Sênior com mais de 12 anos de experiência criando soluções tecnológicas e inovadoras para empresas',
    },
    
  ]

  return (
    <div className="bg-primaryblue py-8">
      <div className="w-full mx-auto max-w-screen-lg">
        <h2 className="bg-primaryblue text-white font-bold text-3xl mx-auto py-4 pl-8">
          Conheça nosso C-Level
        </h2>
      </div>
      <section className="text-white mt-16 md:grid md:grid-cols-2 md:gap-8 max-w-screen-lg mx-auto">
        {
          people.map((person) => (
            <div className="grid grid-rows-2 mx-auto w-80 sm:py-4">
              <img className="sm:w-80 md:w-64 border-primaryblue border-2 rounded-lg duration-200 ease-in row-span-2" src={person.image} onMouseOver={(event) => event.target.src = person.imageHover} onMouseOut={(event) => event.target.src = person.image} alt="" />
              <div className="py-4">
                <h2 className="font-bold text-2xl">{person.name}</h2>
                <h3 className="role-color font-bold">{person.role}</h3>
                <p className="">{person.bio}</p>
              </div>
            </div>
          ))
        }
      </section>
    </div>
  )
}

export default Team;