import React from 'react';

import Footer from './components/Footer';
import Header from './components/Header';

import './assets/css/hr.css';
import './index.css';
import Pitch from './sections/Pitch';
import Team from './sections/Team';
// import Story from './sections/Story';
import Services from './sections/Services';
import Products from './sections/Products';
import CTA from './sections/CTA';

class App extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Pitch />
        <Team />
        {/* <Story /> */}
        <Services />
        <CTA />
        <Products />
        <Footer />
      </>
    )
  }
}

export default App;
