import React from 'react';
import '../assets/css/icon-hover.css';

function ServiceCard({ title, text, icon }) {
  return(
    <div className="hover-class overflow-hidden rounded-xl col-span-1 flex justify-center p-8 bg-gray-50 m-2 hover:scale-105 duration-300 hover:shadow-md">
      <div className="text-center flex flex-col justify-evenly relative">
        <h3 className="font-bold text-2xl py-2 flex flex-row justify-center">
          { title }
        </h3>
        { icon }    
        <p className="text-md">{ text }</p>
      </div>
    </div>
  )
}

export default ServiceCard;